import { api } from '@/api'
import { Template, TemplateLoaderResult, templateTypeValues, TemplateTypeValues } from '@/models/api/template'
import { AxiosError } from 'axios'
import { useProgrammatic } from '@oruga-ui/oruga-next'
import AxiosErrorNotification from '@/components/AxiosErrorNotification.vue'

const { oruga } = useProgrammatic()

// used to find template version in file name.
// From official https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
const semverRegex =
  /(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?/

export const printAxiosError = (aerr: AxiosError): void => {
  oruga.notification.open({
    component: AxiosErrorNotification,
    props: {
      axiosError: aerr
    },
    variant: 'danger',
    position: 'bottom',
    indefinite: true, // don't close automatically, the component will handle it
    closable: true
  })
}

/**
 * Search for the template ID in the file name
 * @param possibleTemplates Array of possible templates
 * @param file File to search for the template ID
 * @returns template ID if found, null otherwise
 */
export const getTemplateIDFromFile = (possibleTemplates: Template[], file: File): string | null => {
  if (possibleTemplates.length === 1) {
    // only one possible template
    return possibleTemplates[0].id
  }
  if (possibleTemplates.length > 1) {
    // find if there's a version, like 1.2.0, in the filename
    const match = file.name.match(semverRegex)
    if (match) {
      const version = match[0]
      const matchTemplates = possibleTemplates.filter((t) => t.version === version)
      if (matchTemplates.length === 1) {
        return matchTemplates[0].id
      }
    }
    return null
  }
  return null
}

// Convert snake_case to camelCase
const snakeToCamel = (str: string) => {
  return str.toLowerCase().replace(
    /([-_][a-z])/g,
    (
      group // for each found groups
    ) =>
      group
        .toUpperCase() // Set first letter to capital
        .replace('-', '') // Remove dash (Yeah this handle kebab-case too :D )
        .replace('_', '') // Remove underscore
  )
}

// shorthand for Object.keys(obj).reduce <=> apply a function to each key of an object
const mapKeys = (obj: any, fn: (key: string) => string): typeof obj => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[fn(key)] = obj[key]
    return acc
  }, {} as any)
}

class TemplateLoaderClass {
  private templateList: { [type in TemplateTypeValues]?: Template[] } = {}
  private latestTemplates: { [type in TemplateTypeValues]?: Template } = {}

  public async loadTemplateList(): Promise<TemplateLoaderResult> {
    if (Object.keys(this.templateList).length === templateTypeValues.length) {
      const ResultSnakeCase = {
        ...this.templateList,
        ...mapKeys(this.latestTemplates, (key) => key + '_latest')
      }

      const ResultCamelCase = mapKeys(ResultSnakeCase, snakeToCamel)

      return ResultCamelCase as TemplateLoaderResult
    }

    await api
      .templateList()
      .then(({ data }) => {
        const templateList = data as Array<Template>
        for (const template of templateList) {
          if (this.templateList[template.name] === undefined) {
            this.templateList[template.name] = []
          }
          template.download_url = `${api.BASE_URL}/template/download?template_id=${template.id}`
          this.templateList[template.name]?.push(template)

          // TODO: Remove this when incidental_occurrence_data-v1.0.0.csv template is decomissioned
          if (template.id === 'incidental_occurrence_data-v1.0.0.csv') {
            template.instructions_url = `${api.BASE_URL}/template/instructions?template_id=${template.id}`
          }
        }

        for (const type of templateTypeValues) {
          this.latestTemplates[type] = this.findLatest(this.templateList[type] || [])
        }
      })
      .catch(printAxiosError)

    // Teplates, but with snake_case keys
    const ResultSnakeCase = {
      ...this.templateList, // Templates list
      ...mapKeys(this.latestTemplates, (key) => key + '_latest') // Latest templates
    }

    // Convert snake_case to camelCase and return it
    const ResultCamelCase = mapKeys(ResultSnakeCase, snakeToCamel)
    return ResultCamelCase as TemplateLoaderResult
  }

  // Find the latest template in a list
  public findLatest(template: Template[]): Template {
    return template.sort((t1, t2) => t2.version.localeCompare(t1.version))[0]
  }

  // Should not really be used :/ prefer loadTemplateList.then(...) instead
  public getLatestTemplate(type: TemplateTypeValues): Template | undefined {
    if (!this.latestTemplates[type]) {
      this.latestTemplates[type] = this.findLatest(this.templateList[type] || [])
    }
    return this.latestTemplates[type]
  }

  // Should not really be used :/ prefer loadTemplateList.then(...) instead
  public getTemplateList(type: TemplateTypeValues): Template[] | undefined {
    if (!this.templateList[type]) {
      throw new Error('No templates found, load the template list first')
    }
    return this.templateList[type]
  }
}

// Singleton ? bro, just export the instance instead of the class :)
export const TemplateLoader = new TemplateLoaderClass()
